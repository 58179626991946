import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.824 1.247-.819.351L2.427 3.56l-1.354.58v11.085h12.389c.048 0 .112.002.19.004.162.005.385.011.638.008a5.44 5.44 0 0 0 .285-.011V4.14L7.824 1.247Zm5.633 14.828H.976a.754.754 0 0 1-.753-.754V4.077c0-.301.18-.574.457-.693l1.412-.605L6.67.817 7.527.45a.754.754 0 0 1 .594 0l6.847 2.934a.754.754 0 0 1 .457.693v11.46c0 .16-.08.275-.148.34a.565.565 0 0 1-.174.11 1.108 1.108 0 0 1-.257.065 4.48 4.48 0 0 1-.543.035 19.797 19.797 0 0 1-.846-.012Z"
      fill="#005950"
    />
  </svg>
)

const DoorToDoorIcon = memo(SvgComponent)
export default DoorToDoorIcon
