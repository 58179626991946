import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.484 11.9c.56.38 1.236.602 1.963.602.674 0 1.303-.19 1.838-.521m-3.8-.08a3.5 3.5 0 1 1 3.8.08m-3.8-.08c-2.898.748-5.069 3.665-5.235 6.755 0 .19.154.344.344.344h13.304c.19 0 .345-.154.344-.344a7.005 7.005 0 0 0-4.957-6.675"
      stroke="#005950"
      strokeWidth={0.6}
    />
    <rect x={1} y={1} width={18} height={18} rx={1} stroke="#005950" strokeWidth={0.6} />
  </svg>
)

const BriefcaseWithUpArrowIcon = memo(SvgComponent)
export default BriefcaseWithUpArrowIcon
