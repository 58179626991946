import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 6.182v3.5c0 .488-.114.97-.332 1.406a1.572 1.572 0 0 0 1.406 2.275h2.1a2.704 2.704 0 0 0 5.408 0h2.099a1.572 1.572 0 0 0 1.406-2.275 3.143 3.143 0 0 1-.332-1.406V6.016a5.878 5.878 0 0 0-11.755 0v.166Zm7.582 7.181H4.673a1.704 1.704 0 0 0 3.409 0ZM1.5 6.016a4.878 4.878 0 0 1 9.755 0v3.666c0 .643.15 1.278.438 1.853a.572.572 0 0 1-.512.828H1.574a.572.572 0 0 1-.512-.828c.288-.575.438-1.21.438-1.853V6.016Z"
      />
    </mask>
    <path
      d="m.168 11.088-.626-.313.626.313Zm3.505 2.275h.7v-.7h-.7v.7Zm5.409 0v-.7h-.7v.7h.7Zm-1 0h.7v-.7h-.7v.7Zm-3.409 0v-.7h-.7v.7h.7Zm7.02-1.828.626-.313-.627.313Zm-10.63 0-.627-.313.626.313Zm.137-4.63v-.723H-.2v.724h1.4Zm0 2.777V6.906H-.2v2.776h1.4Zm-.406 1.72a3.844 3.844 0 0 0 .406-1.72H-.2c0 .38-.088.754-.258 1.093l1.252.626Zm.78 1.261a.872.872 0 0 1-.78-1.262l-1.252-.626c-.755 1.51.343 3.288 2.032 3.288v-1.4Zm2.1 0h-2.1v1.4h2.1v-1.4Zm2.703 2.704a2.004 2.004 0 0 1-2.004-2.004h-1.4a3.404 3.404 0 0 0 3.404 3.404v-1.4Zm2.005-2.004a2.004 2.004 0 0 1-2.005 2.004v1.4a3.404 3.404 0 0 0 3.405-3.404h-1.4Zm2.799-.7h-2.1v1.4h2.1v-1.4Zm.78-1.262a.872.872 0 0 1-.78 1.262v1.4c1.689 0 2.787-1.777 2.032-3.288l-1.252.626Zm-.406-1.719c0 .597.139 1.185.406 1.72l1.252-.627a2.444 2.444 0 0 1-.258-1.093h-1.4Zm0-3.666v3.666h1.4V6.016h-1.4ZM6.377.84a5.178 5.178 0 0 1 5.178 5.177h1.4A6.578 6.578 0 0 0 6.377-.56v1.4ZM1.2 6.016A5.178 5.178 0 0 1 6.377.84v-1.4A6.578 6.578 0 0 0-.2 6.016h1.4Zm0 .166v-.166H-.2v.166h1.4Zm6.882 6.481H4.673v1.4h3.409v-1.4Zm-4.109.7a2.404 2.404 0 0 0 2.404 2.404v-1.4c-.554 0-1.004-.45-1.004-1.004h-1.4Zm2.404 2.404a2.404 2.404 0 0 0 2.405-2.404h-1.4c0 .555-.45 1.004-1.005 1.004v1.4Zm0-15.328A5.578 5.578 0 0 0 .8 6.016h1.4A4.178 4.178 0 0 1 6.377 1.84v-1.4Zm5.578 5.577A5.578 5.578 0 0 0 6.377.44v1.4a4.178 4.178 0 0 1 4.178 4.177h1.4Zm0 3.666V6.016h-1.4v3.666h1.4Zm.364 1.54a3.444 3.444 0 0 1-.364-1.54h-1.4c0 .752.175 1.494.511 2.166l1.253-.626Zm-1.138 1.841c.946 0 1.56-.995 1.138-1.84l-1.253.625a.128.128 0 0 1 .115-.185v1.4Zm-9.607 0h9.607v-1.4H1.574v1.4Zm-1.138-1.84a1.272 1.272 0 0 0 1.138 1.84v-1.4c.095 0 .157.1.115.185l-1.253-.626ZM.8 9.682c0 .534-.125 1.061-.364 1.54l1.253.625a4.844 4.844 0 0 0 .51-2.166H.8Zm0-3.667v3.666h1.4V6.016H.8Z"
      fill="#005950"
      mask="url(#a)"
    />
  </svg>
)

const DoorToDoorIcon = memo(SvgComponent)
export default DoorToDoorIcon
