import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 8.57h5.754c.136 0 .246.11.246.247v10.936c0 .136-.11.246-.246.246H9.246A.246.246 0 0 1 9 19.753l-.09-4.232A.09.09 0 0 1 9 15.43v0m0 0h5.754c.136 0 .246-.11.246-.246V4.246A.246.246 0 0 0 14.754 4H3.246A.246.246 0 0 0 3 4.246v10.937c0 .136.11.246.246.246H9Z"
      stroke="#005950"
      strokeWidth={0.6}
      strokeLinecap="round"
    />
    <path
      d="M7 8.916C7 7.858 7.895 7 9 7s2 .858 2 1.916c0 .886-1.403 2.515-1.859 3.02a.189.189 0 0 1-.282 0C8.403 11.432 7 9.803 7 8.917Z"
      stroke="#005950"
      strokeWidth={0.6}
      strokeLinecap="round"
    />
  </svg>
)

const BriefcaseWithUpArrowIcon = memo(SvgComponent)
export default BriefcaseWithUpArrowIcon
