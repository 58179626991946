import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 13.682V2.197m0 11.485c-1.902-1.142-4.866-1.012-7.245-.879a.5.5 0 0 1-.528-.499v-10M10 13.682V3.407m0 10.275c1.902-1.142 4.866-1.012 7.245-.879a.5.5 0 0 0 .528-.499v-10M10 13.682v.378m0-11.863C8.574 1.289 4.684.613 2.546 1.25a.46.46 0 0 0-.319.448v.605M10 2.197c1.426-.908 5.316-1.584 7.454-.946a.46.46 0 0 1 .319.448v.605M10 2.197v1.21m0 0V14.06m0 0H1.5a.5.5 0 0 1-.5-.5V2.927c0-.205.124-.39.32-.447.267-.076.573-.134.907-.176M10 14.06h8.5a.5.5 0 0 0 .5-.5V2.927a.456.456 0 0 0-.32-.447 6.01 6.01 0 0 0-.907-.176"
      stroke="#005950"
      strokeWidth={0.6}
      strokeLinecap="round"
    />
  </svg>
)

const BookIcon = memo(SvgComponent)
export default BookIcon
